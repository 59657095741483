@font-face {
  font-family: 'Tiempos Headline';
  font-weight: normal;
  src: url("scss/fonts/TiemposHeadlineWeb-Medium.woff"); }

@font-face {
  font-family: 'Tiempos Headline';
  font-weight: 300;
  src: url("scss/fonts/TiemposHeadlineWeb-Light.woff"); }

@font-face {
  font-family: 'Amalfi';
  src: url("scss/fonts/Amalfi-Regular.ttf"); }

@font-face {
  font-family: 'Ballpoint';
  src: url("scss/fonts/Ball-Pen-Regular.ttf"); }

@font-face {
  font-family: 'TheStyledEdit';
  src: url("scss/fonts/thestylededit.ttf"); }

@font-face {
  font-family: 'WhiteOleander';
  src: url("scss/fonts/WhiteOleanderCompact.ttf"); }

.standard-styles .container {
  width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto; }

.standard-styles .error-display {
  color: #ce4848;
  font-family: 'AvertaPE', sans-serif;
  font-size: 16px;
  line-height: 1.33;
  max-width: 400px;
  min-height: 17px;
  padding: 4px 8px 12px 0;
  border-radius: 15px;
  margin: 0 8px 0 0; }
  .standard-styles .error-display span {
    margin-bottom: 4px; }

@media only screen and (min-width: 0px) and (max-width: 599px) {
  .standard-styles .card-spacing, .standard-styles .card {
    margin: 0 8px 16px 8px;
    padding: 6px 16px; } }

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .standard-styles .card-spacing, .standard-styles .card {
    margin: 0 16px 16px 16px;
    padding: 21px 24px; } }

@media only screen and (min-width: 1000px) {
  .standard-styles .card-spacing, .standard-styles .card {
    margin: 0 96px 32px 96px;
    padding: 16px 80px 80px 80px; } }

.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles h1 {
  font-family: 'Tiempos Headline', 'serif';
  font-size: 32px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: normal; }
  @media only screen and (min-width: 1000px) {
    .standard-styles h1 {
      font-family: 'Tiempos Headline', 'serif';
      font-size: 56px;
      font-weight: normal;
      line-height: 1;
      letter-spacing: normal; } }

.standard-styles h6, .standard-styles h2 {
  font-family: 'Tiempos Headline', 'serif';
  font-size: 32px;
  font-weight: normal;
  line-height: 40px;
  letter-spacing: normal; }
  @media only screen and (min-width: 1000px) {
    .standard-styles h6, .standard-styles h2 {
      font-family: 'Tiempos Headline', 'serif';
      font-size: 48px;
      font-weight: 500;
      line-height: 1;
      letter-spacing: normal; } }

.standard-styles h3 {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 48px;
  font-weight: normal;
  line-height: 1;
  letter-spacing: normal; }

.standard-styles h4 {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal; }
  @media only screen and (min-width: 1000px) {
    .standard-styles h4 {
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 30px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: normal; } }

.standard-styles h5 {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal; }
  @media only screen and (min-width: 1000px) {
    .standard-styles h5 {
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 24px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: normal; } }

@media only screen and (min-width: 1000px) {
  .standard-styles h6 {
    font-family: 'Tiempos Headline', 'serif';
    font-size: 32px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: normal; } }

.standard-styles p {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 16px;
  font-weight: normal;
  line-height: 1.45;
  letter-spacing: normal; }
  @media only screen and (min-width: 1000px) {
    .standard-styles p {
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 24px;
      font-weight: normal;
      line-height: 1.17;
      letter-spacing: normal; } }

.standard-styles p.small,
.standard-styles span.small {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 16px;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #28323c; }

.standard-styles p.bold,
.standard-styles span.bold {
  font-weight: bold; }

.standard-styles .eyebrow {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: normal;
  text-transform: uppercase; }

.standard-styles .eyebrow--red {
  color: #ff5a5a; }

.standard-styles label {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal;
  display: block;
  margin-bottom: 8px; }
  @media only screen and (min-width: 1000px) {
    .standard-styles label {
      margin-bottom: 16px; } }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .container {
  width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto; }

.standard-styles .error-display {
  color: #ce4848;
  font-family: 'AvertaPE', sans-serif;
  font-size: 16px;
  line-height: 1.33;
  max-width: 400px;
  min-height: 17px;
  padding: 4px 8px 12px 0;
  border-radius: 15px;
  margin: 0 8px 0 0; }
  .standard-styles .error-display span {
    margin-bottom: 4px; }

@media only screen and (min-width: 0px) and (max-width: 599px) {
  .standard-styles .card-spacing, .standard-styles .card {
    margin: 0 8px 16px 8px;
    padding: 6px 16px; } }

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .standard-styles .card-spacing, .standard-styles .card {
    margin: 0 16px 16px 16px;
    padding: 21px 24px; } }

@media only screen and (min-width: 1000px) {
  .standard-styles .card-spacing, .standard-styles .card {
    margin: 0 96px 32px 96px;
    padding: 16px 80px 80px 80px; } }

.standard-styles .form-group {
  margin: 16px;
  display: block; }

.standard-styles .container {
  width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto; }

.standard-styles .error-display {
  color: #ce4848;
  font-family: 'AvertaPE', sans-serif;
  font-size: 16px;
  line-height: 1.33;
  max-width: 400px;
  min-height: 17px;
  padding: 4px 8px 12px 0;
  border-radius: 15px;
  margin: 0 8px 0 0; }
  .standard-styles .error-display span {
    margin-bottom: 4px; }

@media only screen and (min-width: 0px) and (max-width: 599px) {
  .standard-styles .card-spacing, .standard-styles .card {
    margin: 0 8px 16px 8px;
    padding: 6px 16px; } }

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .standard-styles .card-spacing, .standard-styles .card {
    margin: 0 16px 16px 16px;
    padding: 21px 24px; } }

@media only screen and (min-width: 1000px) {
  .standard-styles .card-spacing, .standard-styles .card {
    margin: 0 96px 32px 96px;
    padding: 16px 80px 80px 80px; } }

.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles h1 {
  font-family: 'Tiempos Headline', 'serif';
  font-size: 32px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: normal; }
  @media only screen and (min-width: 1000px) {
    .standard-styles h1 {
      font-family: 'Tiempos Headline', 'serif';
      font-size: 56px;
      font-weight: normal;
      line-height: 1;
      letter-spacing: normal; } }

.standard-styles h6, .standard-styles h2 {
  font-family: 'Tiempos Headline', 'serif';
  font-size: 32px;
  font-weight: normal;
  line-height: 40px;
  letter-spacing: normal; }
  @media only screen and (min-width: 1000px) {
    .standard-styles h6, .standard-styles h2 {
      font-family: 'Tiempos Headline', 'serif';
      font-size: 48px;
      font-weight: 500;
      line-height: 1;
      letter-spacing: normal; } }

.standard-styles h3 {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 48px;
  font-weight: normal;
  line-height: 1;
  letter-spacing: normal; }

.standard-styles h4 {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal; }
  @media only screen and (min-width: 1000px) {
    .standard-styles h4 {
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 30px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: normal; } }

.standard-styles h5 {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal; }
  @media only screen and (min-width: 1000px) {
    .standard-styles h5 {
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 24px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: normal; } }

@media only screen and (min-width: 1000px) {
  .standard-styles h6 {
    font-family: 'Tiempos Headline', 'serif';
    font-size: 32px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: normal; } }

.standard-styles p {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 16px;
  font-weight: normal;
  line-height: 1.45;
  letter-spacing: normal; }
  @media only screen and (min-width: 1000px) {
    .standard-styles p {
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 24px;
      font-weight: normal;
      line-height: 1.17;
      letter-spacing: normal; } }

.standard-styles p.small,
.standard-styles span.small {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 16px;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #28323c; }

.standard-styles p.bold,
.standard-styles span.bold {
  font-weight: bold; }

.standard-styles .eyebrow {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: normal;
  text-transform: uppercase; }

.standard-styles .eyebrow--red {
  color: #ff5a5a; }

.standard-styles label {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal;
  display: block;
  margin-bottom: 8px; }
  @media only screen and (min-width: 1000px) {
    .standard-styles label {
      margin-bottom: 16px; } }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .container {
  width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto; }

.standard-styles .error-display {
  color: #ce4848;
  font-family: 'AvertaPE', sans-serif;
  font-size: 16px;
  line-height: 1.33;
  max-width: 400px;
  min-height: 17px;
  padding: 4px 8px 12px 0;
  border-radius: 15px;
  margin: 0 8px 0 0; }
  .standard-styles .error-display span {
    margin-bottom: 4px; }

@media only screen and (min-width: 0px) and (max-width: 599px) {
  .standard-styles .card-spacing, .standard-styles .card {
    margin: 0 8px 16px 8px;
    padding: 6px 16px; } }

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .standard-styles .card-spacing, .standard-styles .card {
    margin: 0 16px 16px 16px;
    padding: 21px 24px; } }

@media only screen and (min-width: 1000px) {
  .standard-styles .card-spacing, .standard-styles .card {
    margin: 0 96px 32px 96px;
    padding: 16px 80px 80px 80px; } }

/*
 * We want to instantiate all of the base definitions that have been converted into %mixins,
 * but only once. Since every SCSS file relies on both typography and global, those cannot have any printing
 * fields.
 */
.standard-styles * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html.standard-styles,
.standard-styles body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f4f5f7; }
  html.standard-styles a,
  html.standard-styles a:link,
  html.standard-styles a:visited,
  html.standard-styles a:focus,
  html.standard-styles a:hover,
  html.standard-styles a:active,
  .standard-styles body a,
  .standard-styles body a:link,
  .standard-styles body a:visited,
  .standard-styles body a:focus,
  .standard-styles body a:hover,
  .standard-styles body a:active {
    color: #4c84f5;
    text-decoration: none;
    text-transform: uppercase; }
  html.standard-styles a:hover,
  .standard-styles body a:hover {
    text-decoration: none; }

.standard-styles body {
  min-height: 100%; }

.standard-styles .hidden {
  display: none; }

.standard-styles #react-root {
  height: 100%; }
  .standard-styles #react-root .nav-container {
    height: 74px; }
    @media only screen and (min-width: 1000px) {
      .standard-styles #react-root .nav-container {
        height: 100px; } }

.standard-styles .cardwrapper {
  height: calc(100% - 74px); }
  @media only screen and (min-width: 1000px) {
    .standard-styles .cardwrapper {
      height: calc(100% - 100px); } }

.standard-styles .cardwrapper--dashboard {
  display: flex;
  flex-direction: column; }

.standard-styles .cardwrapper--hide-nav {
  padding-top: 1px;
  margin-top: -1px;
  height: 100%; }

.standard-styles .card {
  max-width: 100%;
  min-height: 100%;
  height: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  background-color: #ffffff;
  box-shadow: 0 2px 25px 1px rgba(0, 0, 0, 0.06);
  position: relative;
  z-index: 1;
  overflow-wrap: break-word;
  word-break: break-word;
  border-radius: 6px; }
  .standard-styles .card a,
  .standard-styles .card a:link,
  .standard-styles .card a:visited,
  .standard-styles .card a:focus,
  .standard-styles .card a:hover,
  .standard-styles .card a:active {
    color: #4c84f5;
    text-decoration: none; }
  .standard-styles .card a:hover {
    text-decoration: underline; }

.standard-styles .card--dashboard {
  min-height: calc(100% - 48px);
  padding-top: 80px;
  padding-bottom: 10%; }
  @media only screen and (min-width: 0px) and (max-width: 599px) {
    .standard-styles .card--dashboard {
      padding-top: 20px; } }
  @media only screen and (min-width: 600px) and (max-width: 999px) {
    .standard-styles .card--dashboard {
      padding-top: 20px; } }

.standard-styles .card--no_bottom_margin {
  margin-bottom: 0; }

.standard-styles .card--max-height {
  height: 100%;
  max-height: 100%;
  min-height: 450px; }

.standard-styles .card--fixed-height {
  height: 100%;
  max-height: 100%;
  padding: 0;
  margin-bottom: 0; }

.standard-styles .card--no-padding {
  padding: 0; }

.standard-styles .card--call-room {
  margin-top: 100px;
  height: calc(100% - 100px);
  min-height: auto; }

.standard-styles .card--avoid-zendesk-button {
  min-height: auto;
  height: calc(100% - 40px); }

.standard-styles hr {
  display: block;
  margin: 16px;
  color: #4c84f5;
  background-color: #4c84f5;
  border-color: #4c84f5;
  max-width: 800px; }

.standard-styles .center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }

.standard-styles .right {
  text-align: right; }

.standard-styles .vcenter, .standard-styles .vvcenter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%; }

.standard-styles .vvcenter {
  padding-top: 50%;
  padding-bottom: 50%;
  flex-direction: column;
  flex-wrap: nowrap; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .vvcenter {
      padding-top: 10%;
      padding-bottom: 10%; } }
  .standard-styles .vvcenter h1 {
    margin: 30px 0 0 0; }
  .standard-styles .vvcenter p {
    margin: 24px 0 40px 0; }

.standard-styles .no-bottom-padding {
  padding-bottom: 0; }

.standard-styles .no-top-padding {
  padding-top: 0; }

.standard-styles .inline {
  display: inline; }

.standard-styles .inline-block {
  display: inline-block; }

.standard-styles .info-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  max-width: 520px; }
  .standard-styles .info-container h1 {
    margin-bottom: 24px;
    max-width: 100%; }
  .standard-styles .info-container p {
    max-width: 100%;
    margin-top: 0; }

.standard-styles .info-container--vcenter {
  height: 100%; }

.standard-styles .info-container--wide {
  max-width: 700px; }

.standard-styles .info-container--fullwidth {
  max-width: none; }

.standard-styles .mobileonly {
  display: block; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .mobileonly {
      display: none; } }

.standard-styles .desktoponly {
  display: none; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .desktoponly {
      display: block; } }

.standard-styles .clearfix {
  clear: both; }

.standard-styles .overflow-y {
  overflow-y: visible; }

.standard-styles .no-scroll {
  overflow: hidden; }

.standard-styles .grow {
  flex: 1; }
